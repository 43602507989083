<template>
    <b-card
        no-body
        class="card-revenue-budget pt-1"
    >
<!--		INFO CARDS-->
		<b-row>
			<b-col cols="12" sm="12" class="ml-auto">
				<div class="d-flex align-items-center">
					<b-form-select
						id="year-select"
						name="year-select"
						class="w-auto my-2 d-inline-block"
						v-model="selectedYear"
						:options="years"
					/>
				</div>
			</b-col>
			<b-col
				v-if="selectedFinanceType != 2"
				cols="12"
				md="12"
				sm="12"
				xl="6"
				lg="6"
			>
				<b-card no-body bg-variant="light">
					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">
						<b-row class="d-flex">
							<b-col cols="2" class="d-flex justify-content-center align-items-center">
								<div>
									<b-avatar size="3em" variant="light-success">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" size="lg"/>
									</b-avatar>
								</div>
							</b-col>
							<b-col cols="10">
								<b-row class="align-items-center justify-content-between mb-50">
									<b-col cols="6" class="pl-0">
										<h3 class="m-0">
											{{ i18nT(`Income`) }}
										</h3>
									</b-col>
									<b-col cols="6">
										<h3
										class="text-success float-right m-0"
									>
										{{ {amount: financeData['income_total']} | currency }}
									</h3></b-col>
								</b-row>
								<b-row class="align-items-center justify-content-between">
									<b-col cols="6" class="pl-0"><p class="m-0">{{ i18nT(`Income (VAT)`) }}</p></b-col>
									<b-col cols="6"><span
										class="text-success float-right"
									>
										{{ {amount: financeData['income_VAT_total']} | currency }}
									</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						</b-card-body>
				</b-card>
			</b-col>
			<b-col
				v-if="selectedFinanceType != 2"
				cols="12"
				md="12"
				sm="12"
				xl="6"
				lg="6"
			>
				<b-card no-body bg-variant="light">
					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">
						<b-row class="d-flex">
							<b-col cols="2" class="d-flex justify-content-center align-items-center">
								<div>
									<b-avatar size="3em" variant="light-success">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" size="lg"/>
									</b-avatar>
								</div>
							</b-col>
							<b-col cols="10">
								<b-row class="align-items-center justify-content-between mb-50">
									<b-col cols="6" class="pl-0 m-0">
										<h3 class="m-0">
											{{ i18nT(`Income (Paid)`) }}
										</h3>
									</b-col>
									<b-col cols="6">
										<h3
										class="text-success float-right m-0"
									>
										{{ {amount: financeData['income_payments_total']} | currency }}
									</h3></b-col>
								</b-row>
								<b-row class="align-items-center justify-content-between">
									<b-col cols="6" class="pl-0"><p class="m-0">{{ i18nT(`Income (VAT Paid)`) }}</p></b-col>
									<b-col cols="6"><span
										class="text-success float-right"
									>
										{{ {amount: financeData['income_VAT_payments_total']} | currency }}
									</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						</b-card-body>
				</b-card>
			</b-col>
			<b-col
				v-if="selectedFinanceType != 1"
				cols="12"
				md="12"
				sm="12"
				xl="6"
				lg="6"
			>
				<b-card no-body bg-variant="light">
					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">
						<b-row class="d-flex">
							<b-col cols="2" class="d-flex justify-content-center align-items-center">
								<div>
									<b-avatar size="3em" variant="light-danger">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'minus']" size="lg"/>
									</b-avatar>
								</div>
							</b-col>
							<b-col cols="10">
								<b-row class="align-items-center justify-content-between mb-50">
									<b-col cols="6" class="pl-0 m-0">
										<h3 class="m-0">
											{{ i18nT(`Expenses`) }}
										</h3>
									</b-col>
									<b-col cols="6">
										<h3
										class="text-danger float-right m-0"
									>
										{{ {amount: financeData['expense_total']} | currency }}
									</h3></b-col>
								</b-row>
								<b-row class="align-items-center justify-content-between">
									<b-col cols="6" class="pl-0"><p class="m-0">{{ i18nT(`Expenses (VAT)`) }}</p></b-col>
									<b-col cols="6"><span
										class="text-danger float-right"
									>
										{{ {amount: financeData['expense_VAT_total']} | currency }}
									</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						</b-card-body>
				</b-card>
			</b-col>
			<b-col
				v-if="selectedFinanceType != 1"
				cols="12"
				md="12"
				sm="12"
				xl="6"
				lg="6"
				fluid
			>
				<b-card no-body bg-variant="light">
					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">
						<b-row class="d-flex">
							<b-col cols="2" class="d-flex justify-content-center align-items-center">
								<div>
									<b-avatar size="3em" variant="light-danger">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'minus']" size="lg"/>
									</b-avatar>
								</div>
							</b-col>
							<b-col cols="10">
								<b-row class="align-items-center justify-content-between mb-50">
									<b-col cols="6" class="pl-0 m-0">
										<h3 class="m-0">
											{{ i18nT(`Expenses (Paid)`) }}
										</h3>
									</b-col>
									<b-col cols="6">
										<h3
										class="text-danger float-right m-0"
									>
										{{ {amount: financeData['expense_payments_total']} | currency }}
									</h3></b-col>
								</b-row>
								<b-row class="align-items-center justify-content-between">
									<b-col cols="6" class="pl-0"><p class="m-0">{{ i18nT(`Expenses (VAT Paid)`) }}</p></b-col>
									<b-col cols="6"><span
										class="text-danger float-right"
									>
										{{ {amount: financeData['expense_VAT_payments_total']} | currency }}
									</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						</b-card-body>
				</b-card>
			</b-col>
			<b-col
				v-if="selectedFinanceType == -1"
				cols="12"
				md="12"
				sm="12"
				xl="6"
				lg="6"
				class="ml-auto"
			>
				<b-card no-body bg-variant="light-success">
					<b-card-body class="px-1 py-1 align-items-center">
						<b-row class="d-flex">
							<b-col cols="2" class="d-flex justify-content-center align-items-center">
								<div>
									<b-avatar size="3em" variant="light-warning">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'chart-line']" size="lg" />
									</b-avatar>
								</div>
							</b-col>
							<b-col cols="10">
								<b-row class="align-items-center justify-content-between mb-50">
									<b-col cols="6" class="pl-0 m-0">
										<h3 class="m-0">
											{{ i18nT(`Balance`) }}
										</h3>
									</b-col>
									<b-col cols="6">
										<h3
											class="float-right m-0"
											:class="financeData['balance_total'] > 0 ? 'text-success' : 'text-danger'"
										>
											{{ {amount: financeData['balance_total']} | currency }}
										</h3></b-col>
								</b-row>
								<b-row class="align-items-center justify-content-between">
									<b-col cols="6" class="pl-0">
										<p class="m-0" style="color: #333333 !important;">
											{{ i18nT(`Balance (VAT)`) }}
										</p>
									</b-col>
									<b-col cols="6"><span
										class="float-right "
										:class="financeData['balance_VAT_total'] > 0 ? 'text-success' : 'text-danger'"
									>
										{{ {amount: financeData['balance_VAT_total']} | currency }}
									</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						</b-card-body>
				</b-card>
			</b-col>



<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				xl="3"-->
<!--				lg="6"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">-->
<!--						<div class="d-flex flex-column">-->
<!--							<div class="d-flex justify-content-start align-items-center">-->
<!--								<b-avatar size="2em" variant="light-success">-->
<!--									<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']"/>-->
<!--								</b-avatar>-->
<!--								<h6 class="pl-1 text-center m-0">{{ i18nT(`Income (VAT)`) }}</h6>-->
<!--							</div>-->
<!--							<h3-->
<!--								class="text-success m-0 mt-50"-->
<!--							>-->
<!--								{{ {amount:financeData['income_VAT_total'] } | currency }}-->
<!--							</h3>-->
<!--						</div>-->
<!--						</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				xl="3"-->
<!--				lg="6"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">-->
<!--						<div class="d-flex flex-column">-->
<!--							<div class="d-flex justify-content-start align-items-center">-->
<!--								<b-avatar size="2em" variant="light-success">-->
<!--									<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']"/>-->
<!--								</b-avatar>-->
<!--								<h6 class="pl-1 text-center m-0">{{ i18nT(`Income (Paid)`) }}</h6>-->
<!--							</div>-->
<!--							<h3-->
<!--								class="text-success m-0 mt-50"-->
<!--							>-->
<!--								{{ {amount:financeData['income_payments_total'] } | currency }}-->
<!--							</h3>-->
<!--						</div>-->
<!--						</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				xl="3"-->
<!--				lg="6"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center sk-info-cards">-->
<!--						<div class="d-flex flex-column">-->
<!--							<div class="d-flex justify-content-start align-items-center">-->
<!--								<b-avatar size="2em" variant="light-success">-->
<!--									<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']"/>-->
<!--								</b-avatar>-->
<!--								<h6 class="pl-1 text-center m-0">{{ i18nT(`Income (VAT Paid)`) }}</h6>-->
<!--							</div>-->
<!--							<h3-->
<!--								class="text-success m-0 mt-50"-->
<!--							>-->
<!--								{{ {amount:financeData['income_VAT_payments_total'] } | currency }}-->
<!--							</h3>-->
<!--						</div>-->
<!--						</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->

<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				lg="12"-->
<!--				xl="3"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center">-->
<!--						<div class="d-flex">-->
<!--							<b-avatar size="2em" variant="light-success">-->
<!--								<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" />-->
<!--							</b-avatar>-->
<!--							<div class="pl-1">-->
<!--								<h6>{{ i18nT(`Income`)}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--							<div class="pl-1 float-lg-right">-->
<!--								<h6>{{ i18nT(`Income (VAT)`)}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_VAT_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--						</div>-->
<!--					</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
<!--			<b-col-->
<!--			cols="12"-->
<!--			md="12"-->
<!--			sm="12"-->
<!--			lg="12"-->
<!--			xl="3"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center">-->
<!--						<div class="d-flex">-->
<!--							<b-avatar size="2em" variant="light-success">-->
<!--								<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" />-->
<!--							</b-avatar>-->
<!--							<div class="px-50">-->
<!--								<h6>{{ i18nT(`Income (Paid)`)}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_payments_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--							<div class="text-nowrap">-->
<!--								<h6>{{ i18nT(`Income (VAT Paid)`)}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_VAT_payments_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--						</div>-->
<!--					</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				lg="12"-->
<!--				xl="3"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center">-->
<!--						<div class="d-flex">-->
<!--							<b-avatar size="4em" variant="light-success">-->
<!--								<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" />-->
<!--							</b-avatar>-->
<!--							<div class="pl-1">-->
<!--								<h6>{{ i18nT(`Income (Paid)`).toUpperCase()}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_payments_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--						</div>-->
<!--					</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
<!--			<b-col-->
<!--				cols="12"-->
<!--				md="12"-->
<!--				sm="12"-->
<!--				lg="12"-->
<!--				xl="3"-->
<!--			>-->
<!--				<b-card no-body border-variant="success">-->
<!--					<b-card-body class="px-1 py-1 align-items-center">-->
<!--						<div class="d-flex">-->
<!--							<b-avatar size="3em" variant="light-success">-->
<!--								<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'plus']" />-->
<!--							</b-avatar>-->
<!--							<div class="pl-1">-->
<!--								<h6>{{ i18nT(`Income (VAT Paid)`).toUpperCase()}}</h6>-->
<!--								<h2-->
<!--									class="m-0 text-success"-->
<!--								>-->
<!--									{{ {amount:financeData['income_VAT_payments_total'] } | currency }}-->
<!--								</h2>-->
<!--							</div>-->
<!--						</div>-->
<!--					</b-card-body>-->
<!--				</b-card>-->
<!--			</b-col>-->
		</b-row>

        <b-row class="pb-2 border-bottom">
            <b-col
                md="12"
                class="revenue-report-wrapper"
            >
                <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                        {{i18nT(`Revenue report`)}}
                    </h4>

                    <div class="d-flex justify-content-between">
                        <transition-group name="fade" mode="out-in">

                        </transition-group>

                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 1 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed  chart-color-1 mr-50" />
                            <span class="">{{i18nT(`Income amount`)}}</span>
                        </div>

                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 1 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed chart-color-2 mr-50" />
                            <span>{{i18nT(`Received income payments`)}}</span>

                        </div>
                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 2 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed chart-color-3 mr-50" />
                            <span>{{i18nT(`Expenses amount`)}}</span>
                        </div>
                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 2 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed chart-color-4 mr-50" />
                            <span>{{i18nT(`Recorded expense payments`)}}</span>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <vue-apex-charts
                    id="revenue-report-chart"
                    type="bar"
                    height="200"
                    :options="chartOptions"
                    :series="series"
                />
            </b-col>

<!--            <b-col-->
<!--                md="4"-->
<!--                class="budget-wrapper"-->
<!--            >-->
<!--                <div class="d-flex justify-content-center align-content-center align-items-center">-->
<!--                    <label for="year-select" class="mr-1 d-inline-block">{{i18nT(`Viewing data for year`)}}</label>-->
<!--                    <b-form-select-->
<!--                        id="year-select"-->
<!--                        name="year-select"-->
<!--                        class="w-auto my-2 d-inline-block"-->
<!--                        v-model="selectedYear"-->
<!--                        :options="years"-->
<!--                    />-->
<!--                </div>-->

<!--                <div v-if="financeData">-->
<!--                    <b-row  v-if="selectedFinanceType != 2">-->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Income`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-1 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['income_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col> -->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Income(VAT)`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-1 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['income_VAT_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col>   -->
<!--                    </b-row>-->
<!--                    <b-row  class="mb-1" v-if="selectedFinanceType != 2">-->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Income (paid)`)}}</h5>-->
<!--                                <h2 class="mb-1 text-color-1 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['income_payments_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col> -->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Income(VAT paid)`)}}</h5>-->
<!--                                <h2 class="mb-1 text-color-1 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['income_VAT_payments_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col>   -->
<!--                    </b-row>-->
<!--                    <b-row v-if="selectedFinanceType != 1">-->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Expenses`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-3 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['expense_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col> -->
<!--                        <b-col cols="12" lg="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Expenses(VAT)`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-3 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['expense_VAT_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col>  -->
<!--                    </b-row>-->
<!--                    <b-row v-if="selectedFinanceType != 1">-->
<!--                        <b-col cols="12" xl="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Expenses(Paid)`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-3 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['expense_payments_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col> -->
<!--                        <b-col cols="12" xl="6">-->
<!--                            <div class="text-nowrap">-->
<!--                                <h5>{{i18nT(`Expenses(VAT Paid)`)}}</h5>-->
<!--                                <h2 class="mb-25 text-color-3 font-weight-bolder">-->
<!--                                    {{ {amount: financeData['expense_VAT_payments_total'], currency: currency} | currency }}-->
<!--                                </h2>-->
<!--                            </div>-->
<!--                        </b-col>  -->
<!--                    </b-row>-->
<!--                </div>-->


<!--                &lt;!&ndash; <div>-->
<!--                    <h5>{{i18nT(`Income`)}}</h5>-->
<!--                    <h2 class="mb-25 text-color-1 font-weight-bolder">-->
<!--                        + {{ {amount: incomeTotal, currency: currency} | currency }}-->
<!--                    </h2>-->
<!--                </div>-->
<!--                <div class="mt-2">-->
<!--                    <h5>{{i18nT(`Expenses`)}}</h5>-->
<!--                    <h2 class="mb-25 text-color-3 font-weight-bolder">-->
<!--                        - {{ {amount: expensesTotal, currency: currency} | currency }}-->
<!--                    </h2>-->
<!--                </div>-->
<!--                <div class="border-top pt-1 mt-1">-->
<!--                    <h5>{{i18nT(`Balance`)}}</h5>-->
<!--                    <h2 class="mb-25 font-weight-bolder" :class="incomeTotal - expensesTotal > 0 ? 'text-color-1' : 'text-color-3'">-->
<!--                        {{ {amount: incomeTotal - expensesTotal, currency: currency} | currency }}-->
<!--                    </h2>-->
<!--                </div> &ndash;&gt;-->

<!--            </b-col>-->
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormSelect,
	BAvatar
    //BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        VueApexCharts,
        BCard,
        BRow,
        BCol,
		BAvatar,
        //BFormGroup,
        BFormSelect
    },
    directives: {
        Ripple
    },
    props: {
        selectedFinanceType: {
            type: String
        }
    },
    data() {
        return {
            report: undefined,
            selectedYear: -1,
            years: [2022],
            expenses: {},
            income: {},
            categories: [],
            currency: 'EUR',
            financeData: null,
            path: this.$route.params.listMode
        }
    },
    computed: {
        colors() {
            let colorSets = [];
            if (this.selectedFinanceType === '1')
                colorSets = [
                    '#28c77a',
                    '#1caf62',
                ]
            else if (this.selectedFinanceType === '2')
                colorSets = [
                    '#ff2667',
                    '#C21146',
                ]
            else
                colorSets = [
                    '#28c77a',
                    '#1caf62',
                    '#ff2667',
                    '#C21146',
                ]
            return colorSets;
        },

        series() {
            let series = []
            if (this.report) {
                const expenses = []
                const incomes = []
                const income_payments = []
                const expense_payments = []
                for (const month in this.report.summary[this.selectedYear]) {
                    if (month == 'total') continue
                    const d = this.report.summary[this.selectedYear][month]
                    expenses.push(d.expense)
                    incomes.push(d.income)
                    income_payments.push(d.income_payments)
                    expense_payments.push(d.expense_payments)
                }
                if (this.selectedFinanceType === '1')
                    series = [
                        { name: this.i18nT(`Income`), data: incomes },
                        { name: this.i18nT(`Received income payments`), data: income_payments },
                    ]
                else if (this.selectedFinanceType === '2')
                    series = [
                        { name: this.i18nT(`Expenses`), data: expenses },
                        { name: this.i18nT(`Recorded expense payments`), data: expense_payments}
                    ]
                else
                    series = [
                        { name: this.i18nT(`Income`), data: incomes },
                        { name: this.i18nT(`Received income payments`), data: income_payments },
                        { name: this.i18nT(`Expenses`), data: expenses },
                        { name: this.i18nT(`Recorded expense payments`), data: expense_payments}
                    ]
            }
            return series
        },
        chartOptions() {
            const categories = []
            let chartOptions = []
            let colors = this.colors;
            if (this.report) {
                for (const month in this.report.summary[this.selectedYear]) {
                    if (month == 'total') continue
                    const d = this.report.summary[this.selectedYear][month]
                    categories.push(`${d.month_name}`)
                }
            }
            chartOptions = {
                chart: {
                    stacked: false,
                    type: 'bar',
                    toolbar: { show: false }
                },
                grid: {
                    padding: {
                        top: -20,
                        bottom: -10
                    },
                    yaxis: {
                        lines: { show: true }
                    }
                },
                xaxis: {
                    categories,
                    labels: {
                        style: {
                            colors: '#6E6B7B',
                            fontSize: '0.86rem',
                            fontFamily: 'Montserrat'
                        }
                    },
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                colors,
                plotOptions: {
                    bar: {
                        //columnWidth: '20%',
                        endingShape: 'rounded'
                    },
                    distributed: true
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#6E6B7B',
                            fontSize: '0.86rem',
                            fontFamily: 'Montserrat'
                        }
                    }
                }
            }
            return chartOptions
        },
        incomeTotal() {
            // if (this.report) return this.report.total.income_total[this.selectedYear]
            return 0
        },
        expensesTotal() {
            // if (this.report) return this.report.total.expense_total[this.selectedYear]
            return 0
        }
    },
    created() {
        this.$http.get(`invoices/summary`).then(({ data }) => {
            this.report = data.data
            const years = []
            for (const year in data.data.summary) {
                years.push(year)
            }
            this.selectedYear = years.at(-1)
            this.years = years
        })
    },
    watch: {
        selectedYear(val) {
            if(val) {
				this.financeData =  this.report.summary[val].total
				this.currency = this.financeData.currency
            }
        }
    }
}
</script>


<style>
.chart-color-1 {
    background-color: #28c77a;
}
.chart-color-2 {
    background-color: #1caf62;
}
.chart-color-3 {
    background-color: #ff2667;
}
.chart-color-4 {
    background-color: #C21146;
}

.chart-color-5 {
    background-color: #79082A;
}

.text-color-1 {
    color: #28c77a;
}

.text-color-2 {
    color: #1caf62;
}

.text-color-3 {
    color: #ff2667;
}

.text-color-4 {
    color: #C21146;
}


.bullet-fixed {
    min-width: 1rem;

}

.sk-info-cards{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

</style>
